var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.contractYears,"label":"Year"},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"pr-8"},[_vm._v("Theater contracts")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":true,"absolute":"","bottom":"","color":"blue-grey darken-3"}})],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.filteredContracts,"items-per-page":100,"dense":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.signature_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.signature_date))+" ")]}},{key:"item.verification_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.verification_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isEditable(item))?_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Manual submission")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Manual submission")]),_c('v-progress-linear',{attrs:{"color":"red darken-3","active":_vm.uploading,"height":10},model:{value:(_vm.percentCompleted),callback:function ($$v) {_vm.percentCompleted=$$v},expression:"percentCompleted"}})],1),_c('v-card-text',[_c('v-form',{ref:"submissionForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',{staticClass:"ml-4 mr-4 mt-4"},[_c('v-col',[_c('v-text-field',{attrs:{"disabled":!_vm.isPending,"label":"Signer's full name","rules":_vm.signatureRules},model:{value:(_vm.signatureName),callback:function ($$v) {_vm.signatureName=$$v},expression:"signatureName"}})],1)],1),_c('v-row',{staticClass:"ml-4 mr-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date signed","prepend-icon":"mdi-calendar","readonly":"","disabled":!_vm.isPending,"rules":_vm.signatureDateRules},model:{value:(_vm.signatureDate),callback:function ($$v) {_vm.signatureDate=$$v},expression:"signatureDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.signDateMenu),callback:function ($$v) {_vm.signDateMenu=$$v},expression:"signDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.signDateMenu = false}},model:{value:(_vm.signatureDate),callback:function ($$v) {_vm.signatureDate=$$v},expression:"signatureDate"}})],1)],1)],1),_c('v-row',{staticClass:"ml-4 mr-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date received/verified","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.verificationDateRules},model:{value:(_vm.verificationDate),callback:function ($$v) {_vm.verificationDate=$$v},expression:"verificationDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.verificationDateMenu),callback:function ($$v) {_vm.verificationDateMenu=$$v},expression:"verificationDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.verificationDateMenu = false}},model:{value:(_vm.verificationDate),callback:function ($$v) {_vm.verificationDate=$$v},expression:"verificationDate"}})],1)],1)],1),_c('v-row',{staticClass:"ml-4 mr-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"show-size":"","label":"Contract","rules":_vm.filenameRules},model:{value:(_vm.fileSelected),callback:function ($$v) {_vm.fileSelected=$$v},expression:"fileSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","disabled":!_vm.formValid},on:{"click":_vm.updateContract}},[_vm._v("Update Contract")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }