<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-select
          :items="contractYears"
          v-model="selectedYear"
          label="Year"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-toolbar>
          <v-toolbar-title class="pr-8">Theater contracts</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="loading"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="filteredContracts"
            :items-per-page="100"
            class="elevation-1"
            dense
            item-key="id"
          >
            <template v-slot:item.signature_date="{ item }">
              {{ formatDate(item.signature_date) }}
            </template>

            <template v-slot:item.verification_date="{ item }">
              {{ formatDate(item.verification_date) }}
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark small>
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="isEditable(item)"
                    text
                    icon
                    v-on="on"
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Manual submission</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="700px">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Manual submission</v-toolbar-title>
          <v-progress-linear
            v-model="percentCompleted"
            color="red darken-3"
            :active="uploading"
            :height="10"
          ></v-progress-linear>
        </v-toolbar>
        <v-card-text>
          <v-form ref="submissionForm" v-model="formValid">
            <v-row class="ml-4 mr-4 mt-4">
              <v-col>
                <v-text-field
                  :disabled="!isPending"
                  v-model="signatureName"
                  label="Signer's full name"
                  :rules="signatureRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- signature date picker -->
            <v-row class="ml-4 mr-4">
              <v-col cols="6">
                <v-menu
                  v-model="signDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="signatureDate"
                      label="Date signed"
                      prepend-icon="mdi-calendar"
                      readonly
                      :disabled="!isPending"
                      :rules="signatureDateRules"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="signatureDate"
                    @input="signDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- verification date picker -->
            <v-row class="ml-4 mr-4">
              <v-col cols="6">
                <v-menu
                  v-model="verificationDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="verificationDate"
                      label="Date received/verified"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="verificationDateRules"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="verificationDate"
                    @input="verificationDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="ml-4 mr-4">
              <v-col cols="12">
                <v-file-input
                  show-size
                  label="Contract"
                  v-model="fileSelected"
                  :rules="filenameRules"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateContract"
            type="submit"
            :disabled="!formValid"
            >Update Contract</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { formatDate } from "@/lib/utils";
import { parseISO } from "date-fns";

const COMPLETED_TEXT = "Completed";
const INPROGRESS_TEXT = "In progress";
const PENDING_TEXT = "Pending";

export default {
  data() {
    return {
      user: null,
      contracts: [],
      search: "",
      dialog: false,
      formValid: false,
      signDateMenu: false,
      signatureName: "",
      canEditSignatureName: false,
      signatureDate: null,
      verificationDateMenu: false,
      verificationDate: null,
      fileSelected: null,
      uploading: false,
      percentCompleted: 0,
      selectedContract: null,
      contractYears: [],
      selectedYear: null,
      headers: [
        { text: "Contract ID", value: "id", sortable: true },
        { text: "Payee ID", value: "payee_id", sortable: true },
        { text: "Payee", value: "payee_name", sortable: true },
        { text: "Year", value: "year", sortable: false },
        { text: "Status", value: "status", sortable: true },
        { text: "Signature date", value: "signature_date", sortable: false },
        {
          text: "Verification date",
          value: "verification_date",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: false,

      signatureRules: [(v) => this.validateSignature(v)],
      signatureDateRules: [(v) => this.validateSignatureDate(v)],
      verificationDateRules: [(v) => this.validateVerificationDate(v)],
      filenameRules: [(v) => this.validateFilename(v)],
    };
  },

  methods: {
    isLoading() {
      return this.loading;
    },

    formatDate(value) {
      // stupid, but here so the linter doesn't complain
      return formatDate(value);
    },

    closeDialog() {
      this.$refs.submissionForm.reset();
      this.dialog = false;
    },

    isInFuture(dateString) {
      return new Date(dateString) > new Date();
    },

    isEditable(item) {
      if (!Object.prototype.hasOwnProperty.call(item, "isEditable")) {
        if (parseISO(item.register_by) < new Date()) {
          item.isEditable = false;
        } else {
          item.isEditable =
            item.status === PENDING_TEXT || item.status === INPROGRESS_TEXT;
        }
      }

      return item.isEditable;
    },

    onProgress(progressEvent) {
      this.percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },

    startUpload(value) {
      this.percentCompleted = 0;
      this.uploading = value;
    },

    updateContract() {
      const data = new FormData();
      data.append("file", this.fileSelected);

      // uploadTheaterContract(contractID, year, formData, progressCallBack) {
      // PUT /api/theater/:id/contract/:year

      // const self = this;

      this.startUpload(true);

      api
        .uploadTheaterContract(
          this.selectedContract.id,
          this.selectedContract.year,
          this.fileSelected.name,
          data,
          this.onProgress
        )
        .then(() => {
          const payload = {
            email: this.user.email,
            contract_id: this.selectedContract.id,
            signer: this.signatureName,
            signature_date: this.signatureDate,
            verified_date: this.verificationDate,
          };

          api.callDBFunc("db-1", "manually_sign_contract", payload).then(() => {
            this.selectedContract.signature_date = this.signatureDate;
            this.selectedContract.verification_date = this.verificationDate;
            this.selectedContract.status = COMPLETED_TEXT;

            // now clear it out and set it back to defaults...
            this.signatureName = "";
            this.fileSelected = null;
            this.signatureDate = new Date().toISOString().substr(0, 10);
            this.verificationDate = new Date().toISOString().substr(0, 10);
          });

          this.startUpload(false);
        })
        .catch(() => {
          this.startUpload(false);
        });

      this.dialog = false;
    },

    validateSignature(v) {
      if (!v) {
        return "Name is required";
      }

      return true;
    },

    validateSignatureDate(v) {
      if (!v) {
        return "Date is required";
      }

      if (this.isInFuture(v)) {
        return "Date cannot be in the future";
      }

      return true;
    },

    validateVerificationDate(v) {
      if (!v) {
        return "Date is required";
      }

      if (this.isInFuture(v)) {
        return "Date cannot be in the future";
      }

      if (new Date(v) < new Date(this.signatureDate)) {
        return "Verification date cannot be before signature date";
      }

      return true;
    },

    validateFilename(v) {
      if (!v) {
        return "File is required";
      }

      if (!v?.name?.toUpperCase().endsWith(".PDF")) {
        return "File must be a pdf";
      }

      return true;
    },

    getContracts() {
      this.loading = true;

      const dt = new Date();
      
      let thisYear = dt.getFullYear();

      // if month Dec, then contract year is really next year so 
      // add 1 before substracting. Note: getMonth returns a zero 
      // based value so Dec is month 11
      if (dt.getMonth() === 11) {
        thisYear += 1;
      }

      const lastYear = thisYear - 1;

      const params = {
        years: [thisYear, lastYear],
      };

      api
        .callDBFunc("db-1", "get_theater_contract_data", params)
        .then((response) => {
          this.contracts = response.data.rows || [];
          this.contractYears = [
            ...new Set(this.contracts.map((x) => x.year)),
          ].sort();
          this.selectedYear = Math.max(...this.contractYears);
          this.loading = false;
        });
    },

    getStatusColor(status) {
      switch (status) {
        case COMPLETED_TEXT:
          return "green darken-3";
        case INPROGRESS_TEXT:
          return "yellow darken-3";
        case PENDING_TEXT:
          return "red darken-3";
        default:
          return "black";
      }
    },

    editItem(item) {
      if (!item) {
        return;
      }

      this.selectedContract = item;

      if (item.status === INPROGRESS_TEXT) {
        this.signatureName = item.contract_info?.contact_name;
        // this.signatureDate = item?.signature_date?.substr(0, 10);
        this.signatureDate = item?.signature_date;
      }

      this.verificationDate = new Date().toISOString().substr(0, 10);

      this.dialog = true;
    },
  },

  computed: {
    isPending() {
      return this.selectedContract?.status === PENDING_TEXT;
    },

    filteredContracts() {
      return this.contracts.filter((x) => x.year === this.selectedYear);
    },
  },

  mounted() {
    this.user = this.$store.getters["user/getUser"];
    this.getContracts();
  },
};
</script>

<style></style>
